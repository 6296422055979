











import Vue from "vue";
import MenuBar from "./components/HeaderComponent.vue";

export default Vue.extend({
  name: "App",
  components: { MenuBar },
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    phone() {
      return this.$data.windowWidth < 650;
    },
  },
  mounted() {
    this.$data.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.$data.windowWidth = window.innerWidth;
    });
  },
});
