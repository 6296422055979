























































































import Vue from "vue";

import LogoComponent from "./LogoComponent.vue";

export default Vue.extend({
  name: "MenuBar",
  components: { LogoComponent },
  data() {
    return {
      windowWidth: 0,
      hamburgerMenuIsOpen: false,
    };
  },
  methods: {
    closeMenu: function (event: any) {
      // `this` inside methods point to the Vue instance
      this.$data.hamburgerMenuIsOpen = false;
    },
  },
  computed: {
    phone() {
      return this.$data.windowWidth < 650;
    },
  },
  mounted() {
    this.$data.windowWidth = window.innerWidth;

    window.addEventListener("resize", () => {
      this.$data.windowWidth = window.innerWidth;
    });
  },
});
